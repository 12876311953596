/* Road Rage */
@font-face {
    font-family: RoadRage;
    src: url("../fonts/Road_Rage.otf") format("opentype");
}

@font-face {
    font-family: Ethnocentric;
    src: url("../fonts/ethnocentric_rg.otf") format("opentype");
}

@font-face {
    font-family: GotischeInitialen;
    src: url("../fonts/GotischeInitialen.ttf") format("opentype");
}

/* Montserrat imported in header from google fonts */