/* Switch GO LIGHT DARK */
#switch {
    position: relative;
    width: 160px;
    height: 50px;
    line-height: 48px;
    background: var(--reverse);
    text-transform: uppercase;
    font-size: 25px;
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: 0.5s;

    -webkit-box-reflect: below 1px linear-gradient(transparent, #0004);
}

#switch span {
    /* position: absolute; */
    display: block;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    text-align: center;
    background: var(--reverse);
    color: var(--third);
    transition: 0.5s;
}

#switch:hover span {
    color: rgba(255, 255, 255, 1);
}

#switch span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: rgba(255, 255, 255, 0.1);
    z-index: 10;
}

#switch::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
            #c0392b,
            #f39c12,
            #f1c40f,
            #2ecc71,
            #3498db,
            #2980b9,
            #9b59b6,
            #8e44ad,
            #c0392b,
            #f39c12,
            #f1c40f,
            #2ecc71,
            #3498db,
            #2980b9,
            #9b59b6,
            #8e44ad);
    background-size: 400%;
    opacity: 0;
    transition: 2.5s;
    animation: eff 20s linear infinite;
}

#switch:hover::before,
#switch:hover::after {
    opacity: 1;
}

#switch::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
            #c0392b,
            #f39c12,
            #f1c40f,
            #2ecc71,
            #3498db,
            #2980b9,
            #9b59b6,
            #8e44ad,
            #c0392b,
            #f39c12,
            #f1c40f,
            #2ecc71,
            #3498db,
            #2980b9,
            #9b59b6,
            #8e44ad);
    background-size: 400%;
    opacity: 0;
    filter: blur(20px);
    transition: 0.5s;
    animation: eff 20s ease infinite;
}

@keyframes eff {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}