/* Typography */
#title,
h1 {
    font-family: 'RoadRage', Impact, 'Arial Narrow Bold', sans-serif;
    font-size: 3em;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--main);

}

/* Kribba Logo */
#title {
    font-size: 14vw;
    text-align: center;
    padding-left: 0px;
    background: -webkit-radial-gradient(circle, var(--main), var(--fourth));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */

    background-size: 400% 400%;
    -webkit-animation: logo 20s ease infinite;
    -moz-animation: logo 20s ease infinite;
    animation: logo 20s ease infinite;

}

#title:after {
    font-family: 'Montserrat', sans-serif;
    font-size: .3em;
    font-weight: 300;
    content: ".com";
}

/* Logo Animation */
@-webkit-keyframes logo {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes logo {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes logo {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}