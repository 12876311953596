/* Color */
:root {

    --main: #f947d3;
    --secondary: #ff52fa;
    --third: #ce73f6;
    --fourth: #7fcbe2;
    --fifth: #3d61d1;
    --grey: #aeadad;
    --lightgrey: #e9e9e9;
    --darkblack: #111111;
    --black: #0e011d;
    --white: #ffffff;
    --bg: var(--white);
    --reverse: var(--black)
}

/* html, .wrapper {
    background: var(--black);
    background: linear-gradient(90deg, var(--black) 0%, var(--darkblack) 100%);
} */

/* Handle layout for larger than mobile screens */
@media only screen and (min-width: 768px) {
    /* Hid text on desktop */
    .visibleOnMobile {
        display: none;
    }
    /* center logo vertically */
    #title {
    padding: 0px 0px;
    }

    #sh-center {
        padding: 0px 0px;
    }

    .modal-content {
        width: 70%;
    }
   /* no grid support? */

    .wrapper {
        grid-template-columns: 1fr;
        margin: 0 auto;
    }
    .twoColumnGrid_2_1 {
        grid-template-columns: 2fr minmax(256px,1fr);
    }
    
    .twoColumnGrid_1_2 {
        grid-template-columns: minmax(256px,1fr) 2fr;
    }

    .twoColumnGrid_1_1 {
        /* used in Projects listing */
        grid-template-columns: 1fr 1fr;
        column-gap: 5em; 
    }
    
    .twoColumnGrid_1_3 {
        grid-template-columns: minmax(256px,1fr) 3fr;
    }
    
    .threeColumnGrid_1_1_1 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    /* style guide button animation */
    .button-slanted {
        bottom: -8px;
        animation: MoveDown 3s ease-in-out 1s ;
        -webkit-animation: MoveDown 3s ease-in-out 1s;
        -moz-animation: MoveDown 3s ease-in-out 1s;
        animation-iteration-count: 1;
    }

    .button-slanted:hover {
        bottom: 0px;
    }

    @keyframes MoveDown {
        0% {
            transform: translateY(-10px) skewX(-20deg);
        }
        100% {
            transform: translateY(0) skewX(-20deg);           
        }
    }
}