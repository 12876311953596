body {

    margin: 0 0px;
    background-color: var(--bg);
}


h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 3em;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5em;
    font-weight: 400;
}  

h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2em;
    font-weight: 400;
}  

h4, nav {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5em;
    font-weight: 400;
}

.header, .footer, nav {
    grid-column: 1 / -1;
    /* needed for the floated layout */
    clear: both;
  }

footer {
    position: fixed;
    bottom: 0px;
}

nav {
    margin: 0 auto;
}

p,div, .button, li, label {
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    font-weight: 300; 
}

p {
    line-height: 1.6;
    max-width: 800px;
}

/* Grids & Layout */

.oneColumnGrid {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1;
}


.twoColumnGrid_2_1 {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1;
}

.twoColumnGrid_1_2 {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1;
}

.twoColumnGrid_1_1 {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1;
}

.twoColumnGrid_1_3 {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1;
}

.threeColumnGrid_1_1_1 {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1;
}

.textCentered {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
}

.notSelectable {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.header, .footer, nav {
    grid-column: 1 / -1;
    /* needed for the floated layout */
    clear: both;
  }

/* The Modal (background) */
.modal {
    display: block; 
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: var(--bg); /* Fallback color */
    background-color: var(--bg); /* Black w/ opacity */
}
  
/* Modal Content/Box */
.modal-content {
    background-color: var(--bg);
    color: var(--main);
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid var(--secondary);
    width: 80%; /* Could be more or less, depending on screen size */
}
  
/* BUTTONS */
/* MODAL BUTTON  */
  .button-slanted {
    background-color: var(--fourth);
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    cursor: pointer;
    border: none;
    padding: 6px;
    position: fixed;
    bottom: 5px;
    left: 10px;
  }

  .button-slanted:hover {
      background-color: var(--fifth);
  }
  
  .button-slanted-content {
    color: var(--black) !important;
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);  
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    font-weight: 300; 
  }

#discount_code {
    cursor: pointer;
}

/* Store */

#sh-center {
    padding: 0px 40px;
}

/* Size Guide Tables */
#size-guide {
    min-width: 360px;
}

#size-guide tr th {
    color: var(--reverse);
    font-weight: 500;
    text-align: left;
    font-size: 0.8em;
    padding: 10px;
}

#size-guide tr td {
    padding: 10px;
    color:var(--reverse);
    font-size: 0.8em;
    border-top: 1px solid var(--main);
    word-break: break-word;
}
#size-guide tr td:first-of-type {
    width: 30%;
}


/* Color */

.mainColor {
    background-color: var(--main);;
}

.secondaryColor {
    background-color: var(--secondary);
}

.thirdColor {
    background-color: var(--third);
}

.fourthColor {
    background-color: var(--fourth);
}

.fifthColor {
    background-color: var(--fifth);
}

.reversedColor {
    background-color: var(--bg);
    color: var(--reverse);
}

.forgroundColor {
    background-color: var(--reverse);
}

.lightgreyColor {
    background-color: var(--lightgrey);
    border: 1px solid var(--black);
}

.greyColor {
    background-color: var(--grey);
}

/* Links */

a {
    color: var(--secondary);
}

a:hover {
    color: var(--third);
}

a:visited {
    color: var(--fifth);
}

ul {
    list-style-type: none;
}

li a::before {
    content: "> ";
}


/* Images */
figure {
    margin: 0;
    text-align: center;
}

figcaption {
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    font-weight: 400; 
}

.responsive {
    width: 100%;
    height: auto;
}

iframe {
    border: 0px;
}

/* tooltip */
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }