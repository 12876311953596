.notice {
    background-color: var(--bg);
    border: 3px solid var(--secondary);
    padding: 1rem;
    margin: 2rem 0;
    text-align: center;
    box-shadow: 3px 3px var(--third), .4em .4em var(--fourth);
}

.notice::before {
    content: "NOTE";
    background: var(--bg);
    width: 5rem;
    border: 3px solid var(--secondary);
    display: block;
    text-align: center;
    position: relative;
    padding: 2px 10px;
    font-weight: bold;
    margin: 0 auto;
    top: -2em;
  }